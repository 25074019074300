import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Barra_bassa from '@widgets/Barra_bassa/Barra_bassa'
import ContactForm from '@widgets/ContactForm'
import PageTitle from '@components/PageTitle'

const Privacy = props => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
      <Barra_bassa />

      <Seo title='Link utili Voli Aerei' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Link Utili'
          subheader=''
        />
      </Stack>

      <Divider />
      <Stack>
        <Main>
          <div>
      <p>
      <ul>
  <li>
    <a href="http://www.aci-europe.org/" target="_blank" rel="noopener">ACI – Europe Airport Council International Europe</a>
  </li>
  <li>
    <a href="http://www.assoclearance.it/index.asp" target="_blank" rel="noopener">Assoclearance Italian Agency for Airport Coordination</a>
  </li>
  <li>
    <a href="http://www.confindustria.it/" target="_blank" rel="noopener">Confindustria</a>
  </li>
  <li>
    <a href="http://www.federtrasporto.it/" target="_blank" rel="noopener">Federtrasporto</a>
  </li>
  <li>
    <a href="http://www.iata.org/Pages/default.aspx" target="_blank" rel="noopener">IATA – International Air Transport Association</a>
  </li>
  <li>
    <a href="https://ibarair.eu/" target="_blank" rel="noopener">IBAR – Italian Board Airline Representative</a>
  </li>
  <li>
    <a href="http://www.itafsc.org/" target="_blank" rel="noopener">IFSC – Italian Flight Safety Committee</a>
  </li>
  <li>
    <a href="http://www.easa.europa.eu/" target="_blank" rel="noopener">EASA – European Aviation Safety Agency</a>
  </li>
  <li>
    <a href="https://www.ecac-ceac.org/" target="_blank" rel="noopener">ECAC – European Civil Aviation Conference</a>
  </li>
  <li>
    <a href="http://www.enac.gov.it/Home/" target="_blank" rel="noopener">ENAC – Ente Nazionale Aviazione Civile</a>
  </li>
  <li>
    <a href="https://www.eurorimborso.it/" target="_blank" rel="noopener">Risarcimenti Aerei - Eurorimborso</a>
  </li>
  <li>
    <a href="https://assaeroporti.com/" target="_blank" rel="noopener">Assoaeroporti</a>
  </li>
  <li>
    <a href="https://www.aeronautica.difesa.it/" target="_blank" rel="noopener">Aeronautica Militare</a>
  </li>
  <li>
    <a href="https://volandia.it/" target="_blank" rel="noopener">Parco e museo del volo</a>
  </li>
  <li>
    <a href="https://www.airvergiate.it/" target="_blank" rel="noopener">Airvergiate</a>
  </li>
</ul>

</p>
          </div>
        </Main>
      </Stack>
      <Stack>
        <Main>
          <ContactForm />
        </Main>
      </Stack>
    </Layout>
  )
}

export default Privacy
